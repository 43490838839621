import model from './model';

type Counter = {
  likeCount: number;
  viewCount: number;
  commentCount: number;
};
export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setCounters: (counter: Counter) => {
        $w('#likeNumber').text = String(counter.likeCount);
        $w('#viewsNumber').text = String(counter.viewCount);
        $w('#commentsNumber').text = String(counter.commentCount);
      },
    },
  };
});
