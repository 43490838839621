import model from './model';

type Rating = { average?: number; total?: number };

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setRatings: (rating: Rating) => {
        $w('#ratingsDisplay').rating = rating.average;
        $w('#ratingsDisplay').numRatings = rating.total;
      },
    },
  };
});
