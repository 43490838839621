import { I$W } from '@wix/yoshi-flow-editor';
import { NormalizedCategory } from '@wix/communities-blog-client-common';
import { AppDataWithSubjects, PostPageRenderModel } from '../../viewer.app';
import model from './model';

// TODO: problem - correct data is shown on 2nd render
export default model.createController((params) => {
  const { $w, $widget, flowAPI } = params;
  const appData = params.appData as AppDataWithSubjects;

  return {
    pageReady: async () => {
      appData?.subjects.postPageRenderModel.subscribe((next) => {
        const { data } = next;
        setPostPageHeader($w, data);
      });

      $widget.fireEvent('widgetLoaded', {});
    },
    exports: model.getExports(),
  };
});

function setPostPageHeader(
  $w: I$W<Record<string, any>>,
  postPageRenderModel: PostPageRenderModel,
) {
  const headerModel = getHeaderData(postPageRenderModel);
  const ui = {
    categories: $w('#categories1'),
    title: $w('#title1'),
    ratings: $w('#ratings1'),
    metadata: $w('#metadata1'),
    counters: $w('#counters1'),
    tags: $w('#tags1'),
  };

  ui.categories.setCategories(headerModel.categories);
  ui.title.setTitle(headerModel.title);
  ui.ratings.setRatings({
    average: headerModel.averageRating,
    total: headerModel.totalRatings,
  });
  ui.metadata.setMetadata({
    writer: headerModel.writer,
    firstPublishedDate: headerModel.firstPublishedDate,
    timeToRead: headerModel.timeToRead,
    lastPublishedDate: headerModel.lastPublishedDate,
  });
  ui.counters.setCounters({
    likeCount: headerModel.likeCount,
    viewCount: headerModel.viewCount,
    commentCount: headerModel.commentCount,
  });
  ui.tags.setTags(headerModel.tags);
}

function getHeaderData(postPageRenderModel: PostPageRenderModel) {
  return {
    title: postPageRenderModel.post.title,
    categories: getPostCategories(
      postPageRenderModel.categories,
      postPageRenderModel.post.categoryIds,
    ),
    averageRating: postPageRenderModel.post.averageRating,
    totalRatings: postPageRenderModel.post.totalRatings,
    writer: postPageRenderModel.post.owner?.name ?? '',
    firstPublishedDate: postPageRenderModel.post.firstPublishedDate,
    timeToRead: postPageRenderModel.post.timeToRead,
    lastPublishedDate: postPageRenderModel.post.lastPublishedDate,
    likeCount: postPageRenderModel.post.likeCount || 0,
    viewCount: postPageRenderModel.post.viewCount || 0,
    commentCount: postPageRenderModel.post.totalComments || 0,
    tags: postPageRenderModel.tags,
  };
}

function getPostCategories(
  blogNormalizedCategories: NormalizedCategory[],
  postCategoryIds: string[],
): NormalizedCategory[] {
  return postCategoryIds.reduce((categories, id) => {
    const category = blogNormalizedCategories.find(
      (blogCategory) => blogCategory._id === id,
    );
    if (!category) {
      return categories;
    }
    return [...categories, category];
  }, [] as NormalizedCategory[]);
}
