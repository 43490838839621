import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTitle: (newTitle: string) => {
        $w('#title').text = newTitle;
      },
    },
  };
});
