import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import model from './model';

type Metadata = {
  writer: string;
  firstPublishedDate: string;
  timeToRead: number;
  lastPublishedDate: string;
};

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setMetadata: (metadata: Metadata) => {
        $w('#text1').text = metadata.writer;

        $w('#text5').text = formatPublishedDate(
          metadata.firstPublishedDate,
          flowAPI,
        );
        $w('#text2').text = formatTimeToRead(metadata.timeToRead);
        $w('#text3').text = formatUpdated(metadata.lastPublishedDate, flowAPI);
      },
    },
  };
});

function formatPublishedDate(date: string, flowAPI: PlatformControllerFlowAPI) {
  return formatDate({
    date,
    lng: flowAPI.controllerConfig.wixCodeApi.site.language,
    timezone: flowAPI.controllerConfig.wixCodeApi.site.timezone,
  });
}

function formatTimeToRead(time: number) {
  return `${time} min read`;
}

function formatUpdated(date: string, flowAPI: PlatformControllerFlowAPI) {
  const formattedDate = formatDateICU({
    date,
    lng: flowAPI.controllerConfig.wixCodeApi.site.language,
    rtfFormatter: '',
    timezone: flowAPI.controllerConfig.wixCodeApi.site.timezone,
  });
  return `Updated: ${formattedDate}`;
}
